import axios from "@/utils/axios"

//寻求报道
export function registerapplyNewsReport({
    channel,
    code,
    company,
    description,
    email,
    expo,
    lang,
    mobile,
    name
}) {
    return axios({
        url: 'api/public/expoRegister/applyNewsReport',
        method: 'post',
        data: {
            channel,
            code,
            company,
            description,
            email,
            expo,
            lang,
            mobile,
            name
        },
    })
}
//获取验证码
export function registersendCode({
    code,
    target,
    time,
    type,
    key
}) {
    return axios({
        url: '/api/public/expoRegister/sendCode',
        method: 'post',
        data: {
            code,
            target,
            time,
            type,
            key

        }
    })
}